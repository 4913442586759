import React, { useEffect, useState } from "react";
import HeaderHome from "../components/headerHome";
import FooterHome from "../components/footerHome";
import LessonsSidebar from "../components/LessonsSidebar";
import Lesson from "../components/Lesson";
import Loading from "../components/Loading";
import Auth from "../components/Auth";
import { getUserQuiz, setUserAnswers } from "../api/quiz";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { updateMe } from "../api/users";

export const query = graphql`
  query {
    categories: allContentfulActionCategories {
      nodes {
        title
        questionIDs
        order
      }
    }
    lessons: allContentfulLessons {
      nodes {
        lessonId
        lessonName
        lessonPlanPdfUrl
        activityPdf {
          file {
            url
          }
        }
        lessonDescription {
          lessonDescription
        }
        tip1Title
        tip1Description {
          tip1Description
        }
        tip2Title {
          tip2Title
        }
        tip2Description {
          tip2Description
        }
        tip3Title
        tip3Description {
          tip3Description
        }
        videoName
        videoPlaceholderImage {
          fluid {
            src
          }
        }
        cloudinaryVideo {
          secure_url
          original_url
        }
        practiceChild
        correctPracticeLevels
        failedMessage {
          failedMessage
        }
      }
    }
  }
`;

const QuizLayoutContainer = styled.div`
  width: 100%;
`;

const QuestionArea = styled.div`
  padding: 0 20px;
`;

const QuizLayoutWrapper = styled.div`
  width: 100%;
  padding-top: 74px;
`;

const StyledLoading = styled(Loading)`
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const Lessons = ({ data, gotoLessonId, location, history }) => {
  const [isActionLoading, setActionLoading] = useState(false);
  const [currentLesson, setCurrentLesson] = useState({});
  const {
    categories: { nodes: categories },
    lessons: { nodes: lessons },
  } = data;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const lessonId = params.get("lessonId");
    if (lessonId) {
      let lesson = lessons.find(lesson => lesson.lessonId === lessonId);
      setCurrentLesson(lesson);
    }
  }, [])

  const [userAnswers, setUserAnswersState] = useState({});
  const [isLoading, setLoading] = useState(true);

  const lessonCategories = categories
    .sort((a, b) => a.order - b.order)
    .map(category => ({
      title: category.title,
      order: category.order,
      lessons: category.questionIDs.map(
        questionId =>
          lessons.find(lesson => lesson.lessonId === questionId) || {}
      ),
    }));

  const sortedLessons = lessonCategories
    .map(item => item.lessons)
    .reduce((prev, next) => prev.concat(next));

  useEffect(async () => {
    try {
      setLoading(true);
      const { userAnswers, hasVisitedProgram } = await getUserQuiz();
      let newUserAnswers = {};
      userAnswers.forEach(answer => {
        newUserAnswers[answer.questionId] = {
          answers: answer.answers,
          result: answer.result,
        };
      });
      setUserAnswersState(newUserAnswers);
      setLoading(false);
    } catch (err) {
      window.location.href = "/not-found";
      setLoading(false);
    }
  }, []);

  // const handleClickLesson = lesson => {
  //   setActionLoading(true);
  //   setTimeout(() => {
  //     window.scroll({ top: 0, left: 0, behavior: "smooth" });
  //     setCurrentLesson(lesson);
  //     setActionLoading(false);
  //   }, 2000);
  // };

  const handleWatchVideo = async (enableRedirection) => {
    setActionLoading(true);
    let currentLessonId = currentLesson.lessonId;
    const newUserAnswers = {
      ...userAnswers,
      [currentLesson.lessonId]: {
        answers: currentLesson.correctPracticeLevels,
        result: true,
      },
    };
    setUserAnswersState(newUserAnswers);
    await setUserAnswers({
      userAnswers: Object.keys(newUserAnswers).map(key => ({
        questionId: key,
        ...newUserAnswers[key],
      })),
    });

    setActionLoading(false);

    let newLesson = sortedLessons.find(
      lesson =>
        !newUserAnswers[lesson.lessonId] ||
        (newUserAnswers[lesson.lessonId] &&
          !newUserAnswers[lesson.lessonId].result)
    );

    if (newLesson && newLesson['lessonId']) {
      setCurrentLesson(newLesson);
      window.history.pushState({}, '', `?lessonId=${newLesson.lessonId}`);
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    } else {
      window.location.href = '/program-finished';
    }
  };


  const renderActions = isLoading ? (
    <Loading />
  ) : (
    <QuizLayoutContainer>
      {isActionLoading && <StyledLoading />}
      <HeaderHome />
      <QuizLayoutWrapper>

        <FontAwesomeIcon
          onClick={() => window.location.href = '/toddler-talk-plan'}
          //onClick={()=>{window.location.href='/program-progress'}}
          icon={faArrowAltCircleLeft}
          size="lg"
          className="mt-5"
          style={{ marginLeft: '10%', fontSize: '32px', cursor: 'pointer' }}
        />
        <Lesson
          data={currentLesson}
          userAnswers={userAnswers}
          onClickNext={(enableRedirection) => { handleWatchVideo(enableRedirection) }}
        />

      </QuizLayoutWrapper>
      <FooterHome />
    </QuizLayoutContainer>
  );

  return <Auth>{renderActions}</Auth>;
};

export default Lessons;
